import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import Helmet from "react-helmet";
import tw, { styled } from "twin.macro";

import EquipmentOwnerSvg from "../../static/images/equipment-owners.svg";
import ExpertsSvg from "../../static/images/experts.svg";
import MaterialOwnerSvg from "../../static/images/material-owners.svg";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout/Layout";

interface TileProps {
  isFull?: boolean;
}

const until = new Date("2020-12-31T00:00:00.000Z");
const promo = {
  isValid: until.valueOf() > Date.now(),
  until: until.toLocaleDateString(),
  discount: 15
};

const tiles = [
  {
    icon: <MaterialOwnerSvg />,
    header: "materialOwners.header",
    price: 149,
    fees: []
  },
  {
    icon: <EquipmentOwnerSvg />,
    header: "equipmentOwners.header",
    price: 459,
    fees: []
  },
  {
    icon: <ExpertsSvg />,
    header: "experts.header",
    price: 19,
    fees: []
  }
];

const Tile = styled.article<TileProps>`
  ${tw`p-16 -ml-px -mt-px`}
  ${tw`bg-white border border-grey shadow-lg`}
  ${({ isFull }) => isFull && tw`flex-1`}

  &:nth-of-type(2) {
    ${tw`z-10`}
  }

  h4 {
    ${tw`my-4 text-lg`}
  }

  h1 {
    ${tw`my-6 text-3xl whitespace-nowrap`}
  }

  h2 {
    ${tw`mt-2 mb-6 text-xl text-turquoise`}
  }

  div {
    ${tw`text-left text-grey-dark`}
  }

  svg {
    ${tw`fill-current text-turquoise inline h-24`}
  }
`;

const Pricing: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Helmet title={t("pricing.header")} />
      <h1 tw="py-16 text-center font-black text-4xl">{t("pricing.header")}</h1>

      {promo.isValid && (
        <section tw="bg-turquoise-dark text-white p-16  text-center">
          <h5 tw="text-lg">{t("pricing.promo.until", { until: promo.until })}</h5>
          <h3 tw="text-3xl">{t("pricing.promo.discount", { discount: promo.discount })}</h3>
        </section>
      )}

      <Container tw="pb-32">
        <section tw="justify-center mt-16 mb-4 text-center lg:flex">
          {tiles.map(tile => (
            <Tile key={tile.header} isFull={true}>
              {tile.icon}
              <h4>{t(tile.header)}</h4>
              <h1>
                €{tile.price} {t("pricing.monthly")}
              </h1>
              {tile.fees.length > 0 && (
                <div>
                  <p>{t("pricing.fee")}</p>
                  <ul>
                    {tile.fees.map(fee => (
                      <li key={fee}>{t(fee)}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Tile>
          ))}
        </section>
        <p tw="text-grey-dark">
          {t("pricing.annually")} {t("pricing.vat")}
        </p>

        <section tw="flex justify-center mt-32 text-center">
          <Tile>
            <MaterialOwnerSvg />
            <EquipmentOwnerSvg />
            <ExpertsSvg />
            <h1>{t("pricing.master.header")}</h1>
            <h2>{t("pricing.master.quotation")}</h2>
            <p>{t("pricing.master.description")}</p>
          </Tile>
        </section>

        <p tw="mt-32 text-center text-2xl">
          {t("pricing.startup")} <br /> <a href="mailto:sales@triangular-pod.com">sales@triangular-pod.com</a>
        </p>
      </Container>
    </Layout>
  );
};

export default Pricing;
